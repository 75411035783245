// Disable Frontend Module Editing
a.btn.jmodedit {
	display: none !important;
}

.content_fixed_right {
	right: -4px;
	.moduletable {
		right: 0;
		margin-right: -320px;
		transition: .3s right ease 0s;
		&.active {
			right: 320px;
		}
		> h3 {
			right: 100%;
			transform: rotate(-90deg);
			transform-origin: 100% 100%;
			box-shadow: -2px -4px 10px -7px #333;
		}
	}
}

.content_fixed_left {
	left: -4px;
	.moduletable {
		left: 0;
		margin-left: -320px;
		transition: .3s left ease 0s;
		&.active {
			left: 320px;
		}
		> h3 {
			left: 100%;
			transform: rotate(90deg);
			transform-origin: 0 100%;
			box-shadow: -5px -4px 10px -7px #333;
		}
	}
}

.content_fixed_right, .content_fixed_left {
	position: fixed;
	z-index: 999;
	.moduletable {
		width: 320px;
		position: relative;
		margin-bottom: 24px;
		box-shadow: $box-shadow;
		> h3 {
			background: $light;
			cursor: pointer;
			margin-bottom: 0;
			display: inline-block;
			padding-left: 12px;
			padding-right: 12px;
			border-radius: 10px 10px 0 0;
			top: -32px;
			height: 32px;
			position: absolute;
			margin-right: -1px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $link;
			padding-top: 6px;
			line-height: 17px;
		}
		> div, > ul.nav {
			background: $light;
			border-radius: 0 0 0 $br;
			min-height: 200px;
			box-shadow: 5px 5px 13px -2px #333;
			padding: 12px;
		}
	}
}

.content_main_right {
	.moduletable {
		@include bgblock();
	}
}

.content_main_top, main, footer, .content_main_bottom, .content_main {
	@include clear();
	.moduletable{
		@include clear();
		margin-bottom: 30px;
		.moduletable{
			margin-bottom: 0;
		}
	}
}

.moduletable {
	&.center{
		text-align: center;
		@media #{$large}{
			text-align: left;
		}
		@include clear();
		margin-bottom: 60px;

	}
	&.intro {
		@include clear();
		padding-top: 48px;
		padding-bottom: 48px;
		@media #{$xlarge}{
			padding-top: 72px;
			padding-bottom: 72px;
		}
	}
	.newsflash {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		.newsflash-item {
			border: 1px solid rgb(238, 238, 238);
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			@media #{$xlarge}{
				width: 47%;
			}
			p {
				font-weight: 300;
			}
			figure{
				margin: 0;
				margin-bottom: 24px;
			}
		}
	}
	ul.social {
		margin-left: 0;
		padding-left: 0;
		li {
			list-style: none;
			float: left;
			margin-right: 24px;
			a {
				i{
					color: $base;
				}
				span {
					&.icon-linkedin {
						@include icon($icon-linkedin, 'none');
					}
					&.icon-xing {
						@include icon($icon-xing, 'none');
					}
					&.icon-instagram{
						@include icon($icon-instagram, 'none')
					}

				}
				&:hover{
					i, span{
						color: $link;
					}
				}
			}
		}
	}
}

.acym_front_page fieldset {
	border: 1px solid #ddd;
	padding: 24px;
	margin-top: 48px;
	margin-bottom: 48px;
}

label.cell > span {
	display: inline-block;
	margin-right: 12px;
}

.acym_radio_group {
	margin-right: 12px;
}
