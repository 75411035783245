.pln {
	color: $base
}

@media screen {
	.str {
		color: #db0f0f
	}
	.kwd {
		color: #3f3fff
	}
	.com {
		color: #05b305
	}
	.typ {
		color: #606
	}
	.lit {
		color: #066
	}
	.pun, .opn, .clo {
		color: #660
	}
	.tag {
		color: #008
	}
	.atn {
		color: #606
	}
	.atv {
		color: #080
	}
	.dec, .var {
		color: #606
	}
	.fun {
		color: red
	}
}

@media print, projection {
	.str {
		color: #060
	}
	.kwd {
		color: #006;
		font-weight: bold
	}
	.com {
		color: #600;
		font-style: italic
	}
	.typ {
		color: #404;
		font-weight: bold
	}
	.lit {
		color: #044
	}
	.pun, .opn, .clo {
		color: #440
	}
	.tag {
		color: #006;
		font-weight: bold
	}
	.atn {
		color: #404
	}
	.atv {
		color: #060
	}
}

pre.prettyprint {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
	border: 1px solid #888
}

ol.linenums {
	margin-top: 0;
	margin-bottom: 0
}

li.L0, li.L1, li.L2, li.L3, li.L5, li.L6, li.L7, li.L8 {
	list-style-type: none
}

li.L1, li.L3, li.L5, li.L7, li.L9 {
	background: #eee
}
.prettyprinted *{
	font-family: 'monospace';
}
