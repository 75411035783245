div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media #{$xlarge}{
					margin-left: 24px;
				}
				&:first-child {
					margin-left: 0;
				}
				a {
					@include font-size(12);
				}
			}
		}
	}
}

nav.top_menu {
	.moduletable {
		float: right;
		overflow: visible;
		&.slidenav1 {
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			li {
				display: inline-block;
				list-style: none;
				position: relative;
				&.parent {
					> a, > span {
						&:after {
							content: '';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg) translate(2px, 50%);
							left: 50%;
							width: 0;
							height: 0;
							border-top: 4px solid transparent;
							border-bottom: 4px solid transparent;
							border-left: 4px solid $main;
							display: block;
						}
					}
				}
				> a, > span {
					color: $base;
					margin: 17px 9px 0;
					transition: .4s all ease 0s;
					text-decoration: none;
					display: block;
					@include font-size(15);
					font-weight: 500;
					padding-bottom: 33px;
					padding-top: 18px;
					background: transparent !important;
					position: relative;
					text-transform: uppercase;
					cursor: pointer;
					@media #{$xlarge}{
						margin: 17px 12px 0;
					}
					&:before {
						content: '';
						position: absolute;
						display: block;
						bottom: 0px;
						left: 0;
						width: 0;
						height: 3px;
						background: $link;
						color: $light;
						transition: .3s all ease 0s;
					}
				}
				&:hover {
					> a, > span {
						&:before {
							width: 100%;
						}
					}
				}
				ul.nav-child {
					position: absolute;
					top: 150%;
					left: 0;
					padding-left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					visibility: hidden;
					opacity: 0;
					background: $light;
					z-index: 99;
					min-width: 152px;
					box-shadow: 0px 19px 20px -20px #000;
					li {
						display: block;
						float: none;
						position: relative;
						margin: 0;
						&:first-child a {
							border-left: 0;
						}
						a, span {
							@include font-size(14);
							padding: 12px 10px;
							margin: 0;
							background: $light;
							text-transform: none;
							letter-spacing: 0;
							font-family: $base-font-family;
							font-weight: 500;
							color: $base;
							border-right: 0;
							&:after {
								content: "";
								transform: none;
								left: auto;
								right: 7px;
								top: 43%;
							}
							&:before {
								display: none;
							}
							&:first-child {
								border-left: 0;
							}
						}
						ul {
							left: 130%;
							top:0;
							opacity: 0;
							visibility: hidden;
						}
						&:hover {
							> a, span{
								color: $link;
							}
							> ul.nav-child {
								opacity: 1;
								display: block;
								visibility: visible;
								left: 100%;
								top: 0;
							}
						}
						&.current.active > a{
							color: $main;
						}
					}
				}
				&:hover {
					> ul.nav-child {
						opacity: 1;
						display: block;
						visibility: visible;
						top: 100%;
					}
				}
				&.active {
					> a, > span {
						color: $border;
						&:before {
							width: 100%;
							background: $main;
						}
					}
					ul {
						li {
							a {
							}
						}
					}
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: block;
				list-style: none;
				margin-right: 12px;
				width: 100%;
				@media #{$xlarge}{
					margin-right: 24px;
				}
				&:last-child {
					margin-right: 0;
				}
				a {
					@include font-size(16);
					color: $base;
					display: block;
					font-weight: 300;
				}
				a:hover {
					color: $link;
				}
			}
		}
	}
}

.slidenav1, .slidenav2, .slidenav3, .slidenav4 {
	display: none;
	@media #{$large}{
		display: block;
	}
}

/* Icon 4 */
#main-nav {
	width: 30px;
	height: 45px;
	position: relative;
	margin: 24px auto 0;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	float: left;
	display: block;
	@media #{$large}{
		display: none;
	}
	span, &:before, &:after {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $base;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		content: '';
		transform-origin: left center;
	}
	&:after {
		content: '';
		top: 13px;
		transform-origin: left center;
	}
	span {
		top: 26px;
		transform-origin: left center;
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 19px;
		left: 8px;
	}
	&.open {
		span, &:before, &:after {
			background: $link;
		}
	}
}
.eb-event-date.btn-inverse {
	background: #000;
}
