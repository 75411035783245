* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $link;
	font-size: inherit;
	line-height: inherit;
	transition: .5s all ease-in-out 0s;
	&:hover {
		color: $link_hover;
	}
}

span, strong, div {
	font-size: inherit;
	line-height: inherit;
	color: $base;
}

h1 {
	@include font-size(36, 1.4);
	@media #{$large}{
		@include font-size(48, 1.4);
	}
	margin-bottom: 24px;
	margin-top: 0;
	color: $main;
	font-weight: 400;
	* {
		color: inherit;
		font-size: inherit !important;
		font-weight: inherit;
	}
}

h2 {
	@include font-size(28);
	@media #{$large}{
		@include font-size(36);
	}
	margin-bottom: 24px;
	margin-top: 0;
	color: $main;
	font-weight: 900;
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

h3 {
	@include font-size(22);
	@media #{$large}{
		@include font-size(26);
	}
	margin-top: 0;
	font-weight: 500;
	color: $main;
	margin-bottom: 12px;
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

h4 {
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 0;
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

h5 {
	color: $link;
	font-weight: 300;
	text-transform: uppercase;
	@include font-size(12);
	letter-spacing: 1px;
	margin-top: 0;
	margin-bottom: 0;
}

h6 {
	color: $base;
	@include font-size(12);
	letter-spacing: 1px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
}

p {
	color: $base;
	@include font-size(16);
	margin-bottom: 24px;
	font-weight: 300;
}

div {
	color: $base;
	font-weight: 300;
	@include font-size(16);
}

strong {
	font-weight: 500;
}

ul, ol {
	margin-left: 24px;
	li {
		line-height: 24px;
	}
}

.item-page, .fbTopic_content, .eb-description {
	ul, ol {
		margin-bottom: 24px;
	}
	ul:not(.gkBullet1, .gkBullet2, .gkBullet3, .gkBullet4, .nav) {
		list-style: none;
		margin-left: 0;
		li{
			padding-left: 24px;
			margin-bottom: 6px;
			position: relative;
			&:before{
				content:'';
				position: absolute;
				left: 3px;
				width: 12px;
				top: 8px;
				height: 5px;
				display: block;
				border-top: 2px solid $link;
				border-right: 2px solid $link;
			}
			ul{
				list-style: initial;
				margin-left: 24px;
				padding-top: 6px;
				li{
					padding-left: 0;
					&:before{
						display: none;
					}
				}
			}
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	height: 1px;
	width: 100%;
	background-color: $dark;
	clear: both;
	margin-bottom: 24px;
}

.item-image {
	display: block;
	margin: 0 auto 6px;
	text-align: center;
	.img_caption {
		@include font-size(14);
		max-width: 100%;
	}
	&.pull-right {
		max-width: 100%;
		@media #{$medium}{
			float: right;
			max-width: 50%;
			margin-left: 36px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: right;
			padding-top: 12px;
		}
	}
	&.pull-left {
		max-width: 100%;
		@media #{$medium}{
			float: left;
			max-width: 50%;
			margin-right: 36px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: left;
			padding-top: 12px;
		}
	}
}

/*Artikelinfos*/
dl.article-info {
	@include font-size(12);
	margin-bottom: 12px;
	@include clear();
	span {
		@include font-size(12);
	}
	time {
		@include font-size(12);
	}
	dd {
		display: block;
		float: left;
		margin-right: 12px;
		a {
			@include font-size(12);
		}
		&.published {
			float: right;
		}
	}
	dd.createdby {
		@include font-size(12);
		span {
			@include font-size(12);
		}
	}
}
.com-tags-tag.tag-category {
	margin-top: 116px;
}
/*Pagination*/
div.pagination {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	margin-top: 24px;
	nav.pagination__wrapper{
		float: left;
	}
	p.counter {
		display: none;
		margin-bottom: 0;
		@media #{$large}{
			float: right;
			display: block;
		}
	}
	ul {
		margin-left: 0;
		width: 100%;
		li {
			list-style: none;
			display: inline;
			text-align: center;
			margin-right: 10px;
			a {
				padding: 4px 12px 2px;
				color: $base;
				background: $light;
				border: 2px solid $base;
				text-align: center;
				font-weight: 700;
				display: inline-flex;
				@include font-size(14);
				span {
					line-height: inherit;
					font-size: inherit;
					color: inherit;
				}
				&:hover {
					color: $light;
					background: $link;
					border-color: $link;
				}
			}
			> span {
				padding: 4px 12px 2px;
				color: $base;
				text-align: center;
				display: inline-flex;
				@include font-size(14);
				border: 1px solid $base;
				font-weight: normal;
				position: relative;
				height: 32px;
				top: -5px;
				>span {
					position: relative;
					top: 5px;
				}
			}
			&.pagination-end, &.pagination-start {
				display: none;
			}
			&.pagination-next {
				@media #{$large-max}{
					float: right;
					margin-right: 0;
				}
			}
			@media #{$large-max}{
				display: none;
				&.pagination-prev, &.pagination-next {
					display: inline-block;
				}
			}
		}
	}
}

ul.pagenav {
	width: 100%;
	float: left;
	clear: both;
	margin-left: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			background: $main;
			color: $light;
			padding: 5px;
			@include font-size(12);
			display: block;
			margin-bottom: 12px;
			&:hover {
				background: darken($main, 10%);
			}
		}
	}
	li.pagenav-prev {
		float: left;
		a:before {
			content: '<';
			padding-right: 5px;
		}
	}
	li.pagenav-next {
		float: left;
		clear: both;
		@media #{$medium}{
			float: right;
			clear: none;
		}
		a:after {
			content: '>';
			padding-left: 5px;
		}
	}
}

iframe {
	max-width: 100%;
}

video {
	max-width: 100%;
	height: auto;
}

input#button6, input.button, .moduletable .button, .btn, input[type='submit'] {
	@include button();
	margin-bottom: 12px;
}

/* Fieldset reset */
fieldset {
	border: 0;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
	.btn-group {
		float: left;
		label.element-invisible {
			display: none;
		}
		#filter-search {
			min-width: 210px;
			padding: 4px;
			color: $light;
			border-radius: 3px;
			@include font-size(14);
		}
		&.pull-right {
			float: right;
			select {
				border: 1px solid $border;
			}
		}
	}
}

.gkHighlight1 {
	background: #ffd;
}

.gkHighlight1, .gkHighlight2 {
	cursor: default;
	padding: 1px 5px;
	text-shadow: none;
}

ul.gkBullet1, ul.gkBullet2, ul.gkBullet3, ul.gkBullet4 {
	margin: 2em 0;
	padding: 0;
	list-style-type: none;
	li {
		list-style: none;
		overflow: inherit;
		padding: 2px 0 2px 32px;
		position: relative;
		&:before {
			font-family: $fontawesome;
			position: absolute;
			top: 2px;
			left: 0;
		}
	}
}

ul.gkBullet1 li:before {
	color: #eb1e00;
	content: "\f00c";
	font-weight: 900;
}

ul.gkBullet2 li:before {
	color: $link;
	content: "\f14a";
}

ul.gkBullet3 li:before {
	color: $link;
	content: "\f005";
	font-weight: 900;
}

ul.gkBullet4 li:before {
	color: $link;
	content: "\f0a9";
	font-weight: 900;
}

.gkHighlight2 {
	background: #f5f5f5;
}

p.numblocks {
	margin: 0 0 24px;
	min-height: 48px;
	padding: 0 0 0 82px !important;
	position: relative;
}

pre, code {
	border: 1px solid #e5e5e5;
	border-left: 5px solid #eb1e00;
	color: #272727;
	padding-left: 32px;
}

@media #{$large}{
	.demo-typo-col2 {
		float: left;

		padding-left: 12px;
		padding-right: 12px;
		width: 50%
	}
	.demo-typo-col3 {
		float: left;

		padding-left: 12px;
		padding-right: 12px;
		width: 33%
	}
	.demo-typo-col4 {
		float: left;
		width: 25%;

		padding-left: 12px;
		padding-right: 12px;
	}
}
.gkblock-1, .gkblock-2, .gkblock-3, .gkblock-4, .TEDblock-1,.TEDblock-2, .TEDblock-3, .TEDblock-4 {
	margin: 36px 0;
	padding: 15px 20px;
	&:not(.wf-columns, .wf-column){
		@include clear();
	}
}
.gkblock-1, .TEDblock-1 {
	border: 1px solid #e5e5e5;
}
.gkblock-2, .TEDblock-2 {
	border: 1px solid #e5e5e5;
	background: #f0f0f0;
}
.gkblock-3, .TEDblock-3 {
	background: #ddf0f5;
	border: 0;
}
.gkblock-4, .TEDblock-4 {
	background: #e4f4f2;
}
.typoDesc {
	@include clear();
	margin-bottom: 24px;
}
.bubble-1, .bubble-2 {
	margin: 20px 0 40px;
	padding: 15px 20px;
	position: relative;
	cite {
		bottom: -25px;
		display: block;
		font-size: 13px;
		font-weight: 500;
		height: 25px;
		left: 26px;
		line-height: 32px;
		padding-left: 40px;
		position: absolute;
	}
	&:after {
		border-color: transparent;
		border-style: solid;
		border-width: 22px 0 0 22px;
		bottom: -22px;
		content: "";
		display: block;
		left: 30px;
		position: absolute;
		width: 0;
	}
}
.bubble-1 {
	background: #f6f6f6;
	color: #555;
	cite {
		color: #272727;
	}
	&:after {
		border-color: #f5f5f5;
		border-left: 22px solid transparent;
	}
}
.bubble-2 {
	background: $link;
	color: $light;
	*:not(cite){
		color: inherit;
	}
	  cite {
		color: $link;
	}
	&:after {
		border-color: $link;
		border-left: 22px solid transparent;
	}
}
.gkBlockTextLeft {
	float: left;
	padding-left: 0;
	text-align: left;
}
.gkBlockTextRight {
	float: right;
	padding-right: 0;
	text-align: right;
}
.gkBlockTextCenter {
	margin: 0 auto;
	text-align: center;
}
.gkBlockTextLeft, .gkBlockTextRight, .gkBlockTextCenter {
	display: block;
	font-style: italic;
	font-weight: 400;
	line-height: 170%;
	padding: 25px;
	width: 38%;
}
blockquote {
	font-style: italic;
	font-weight: 400;
	margin: 40px 0;
	color: #000;
	display: block;
	padding: 0 0 0 66px;
	position: relative;
	clear: both;
	&:before {
		color: #EB570D;
		content: "\f10d";
		font-weight: 900;
		font-family: $fontawesome;
		font-size: 32px;
		line-height: 0;
		position: absolute;
		left: 0;
		top: 15px;
	}
	&.gkBlockquote1{
		&:before{
			color: $base;
		}
	}
}
div.gkLegend1 {
	border: 4px double #e5e5e5;
	margin: 30px 0;
	padding: 6px;
	h4 {
		background: #fff;
		color: #242424;
		float: left;
		font-size: 14px;
		font-weight: 400;
		left: 11px;
		line-height: 18px;
		margin: 0 0 -9px !important;
		padding: 0 10px;
		position: relative;
		text-transform: uppercase;
		top: -16px;
	}
	p {
		clear: both;
		margin: 7px;
	}
}

.loading_screen {
	width: 100%;
	position: relative;
	text-align: center;
	transform: scale(.4);
	.lds-roller {
		display: inline-block;
		position: relative;
		width: 32px;
		height: 32px;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 32px 32px;
	}
	.lds-roller div:after {
		content: " ";
		display: block;
		position: absolute;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: $light;
		box-shadow: 0 0 4px black;
		margin: -3px 0 0 -3px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 50px;
		left: 50px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 54px;
		left: 45px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 57px;
		left: 39px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 58px;
		left: 32px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 57px;
		left: 25px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 54px;
		left: 19px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 50px;
		left: 14px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 45px;
		left: 10px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.tooltip.fade {
	display: none !important;
}

p.hint {
	@include font-size(14);
	color: lighten($dark, 20%);
}

/* Table */
table {
	max-width: 100%;
	width: 100%;
	color: $dark;
	margin-bottom: 24px;
	th {
		background-color: $dark;
		padding: 3px 5px;
		color: $light;
		text-align: left;
		@include font-size(12);
		transition: .5s all ease 0s;
		* {
			color: $light;
			margin-bottom: 0;
		}
		@media #{$medium}{
			@include font-size(16);
		}
	}
	tbody {
		tr {
			transition: .5s background-color ease 0s;
			td {
				padding: 3px 5px;
				vertical-align: top;
			}
		}
	}
	&.responsive {
		width: 100%;
		color: $dark;
		margin-bottom: 24px;
		table-layout: fixed;
		th {
			background-color: $dark;
			padding: 5px 10px;
			color: $light;
			text-align: left;
			@include font-size(12);
			transition: .5s all ease 0s;
			* {
				color: $light;
				margin-bottom: 0;
			}
			@media #{$medium}{
				@include font-size(16);
			}
		}
		tbody {
			tr {
				transition: .5s background-color ease 0s;
				td {
					padding: 5px 5px;
					vertical-align: top;
					@media #{$medium-max}{
						width: 50% !important;
						display: block;
						float: left;
						word-break: break-word;
					}
					@media #{$small-max}{
						width: 100% !important;
					}
					img {
						margin: 0 auto 24px;
						display: block;
						float: none !important;
					}
					@media #{$small}{
					}
					@media #{$medium}{
						padding: 5px 10px;
					}
					@media #{$large}{
						float: none;
						display: table-cell;
						box-sizing: border-box;
						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}
					}
					a {
						font-weight: bold;
					}
				}
			}
		}
	}
	&.zebra {
		border: 1px solid $border;
		tr {
			&:nth-child(odd) {
				background-color: $bg;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			&:nth-child(even) {
				background-color: $light;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			td {
				@media #{$medium}{
					border-right: 1px solid $border;
				}
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					padding-left: 5px;
				}
			}
		}
	}
	&.info {
		width: auto;
		@media #{$small-max}{
			width: 100% !important;
		}
		th, th * {
			@include font-size(13);
		}
		tr {
			td {
				@include font-size(13);
				* {
					@include font-size(13);
				}
				&:first-child, &:last-child {
					padding: 5px 5px;
					@media #{$medium}{
						padding: 5px 10px;
					}
				}
			}
		}
	}
	&.sticky {
		min-width: 724px;
		tr {
			td {
				@media #{$medium-max}{
					width: auto !important;
					display: table-cell;
					float: none;
					border-right: 1px solid $border;
					word-break: normal;
				}
				@media #{$small-max}{
					width: auto !important;
				}
			}
		}
	}
	&.stacked {
		width: 100% !important;
		tr {
			border-bottom: 1px solid $link_hover;
			margin-bottom: 1px;
			padding-bottom: 1px;
			&:first-child {
				td {
					font-weight: bold;
					* {
						margin-bottom: 0;
					}
				}
			}
			> td {
				padding: 5px !important;
				text-align: center;
				* {
					margin-bottom: 0;
					text-align: center;
				}
			}
			td:first-child {
				font-weight: bold;
				padding: 5px 10px;
				background: darken($bg, 10%);
			}
			td:nth-child(2) {
				background: lighten(#ddd, 10%)
			}
			td:nth-child(3) {
				background: lighten(#ddd, 5%)
			}
			td:nth-child(4) {
				background: #ddd;
			}
		}
	}
	&.category {
	}
	&.dfContactTable {
		tr th {
			width: 54px;
			padding: 5px 10px;
			text-align: center;
		}
	}
}

.sketch_grid {
	margin-bottom: 24px;
	&.tleft {
		float: left;
	}
}

*::selection {
	background: $link;
	color: $light;
}

// Inputs
// Base Styling Inputs
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea, input[type='search'] {
	border: 2px solid $border;
	margin-bottom: 12px;
	background: $light;
	border-radius: 6px;
	min-height: 32px;
	padding: 2px 8px;
	@include font-size(15);
	&[readonly], &[readonly='readonly'] {
		background: #ddd;
		cursor: not-allowed;
	}
}

textarea {
	border: 1px solid $border;
	padding: 2px;
}

select {
	border: 1px solid $border;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>") $light no-repeat 98.5% !important; /* !important used for overriding all other customisations */
	background: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>") $light no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
	background-size: 12px !important;
}

.sketch_calendar {
	.btn {
		padding: 1px 11px;
		border-radius: 0 5px 5px 0;
		span.icon-calendar {
			@include icon($icon-calendar);
			&:before {
				padding-right: 0;
			}
		}
	}
}

// In Item-page
// Article Summary
.article-index {
	background: $bg;
	padding: 12px;
	margin-bottom: 24px;
	&.pull-right {
		float: right;
	}
	&.pull-left {
		float: left;
	}
}

ul.tags.inline {
	@include clear();
	margin-left: 0;
	margin-bottom: 24px;
	li {
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		&:last-child {
			margin-right: 0;
		}
		a {
			@include font-size(13);
			background-color: $link;
			color: $light;
			padding: 2px 5px;
			&:hover {
				background-color: $link_hover;
			}
		}
	}
}

select#limit {
	width: 75px;
}

.pagenavcounter {
	float: right;
	background: $dark;
	color: $light;
	border-radius: 8px;
	padding: 4px 8px;
	clear: right;
	margin-bottom: 24px;
}

fieldset.filters.btn-toolbar .btn {
	padding: 3px 9px;
	span.icon-search {
		@include icon($icon-search, 'none');
	}
	span.icon-remove {
		@include icon($icon-cross, 'none');
	}
	&:hover {
		span {
			color: $light;
		}
	}
}

ul.category.list-striped {
	list-style: none;
	margin-left: 0;
	>li {
		border-bottom: 1px solid $border;
		@include clear();
		margin-bottom: 24px;
		h3 {
			@include font-size(20);
		}
		ul{
			margin-bottom: 24px;
		}
		>a {
			display: block;
			float: left;
			width: 236px;
			margin-right: 24px;
			margin-bottom: 12px;
			position: relative;
			z-index: 2;
			@media #{$small-max}{
				width: 100%;
				margin-right: 0;

			}
		}
		.bubble-1{
			display: inline-block;
		}
	}
}

ul.gkBullet3, .eb-category-description ul, ul.gkBullet1, ul.gkBullet2 {
	margin-bottom: 24px;
}

p.gkInfo1, p.gkTips1, p.gkWarning1 {
	padding: 12px 12px 12px 66px;
	position: relative;
	&:before {
		font-family: $fontawesome;
		font-size: 24px;
		position: absolute;
		top: 5px;
		left: 22px;
	}
}

p.gkInfo1 {
	background-color: #f8f8f8;
	color:$base;
	&:before {
		content: '\f27a';
		font-weight: 900;
	}
}

p.gkTips1 {
	background-color: #d5f6fd;
	color: $base;
	&:before {
		content: "\f0eb";
	}
}

p.gkWarning1 {
	background-color: $link;
	color: #fff;
	&:before {
		content: "\f057";
	}
}

.pager {
	ul {
		width: 100%;
		float: left;
		clear: both;
		margin-top: 24px;
		li {
			padding-left: 0;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.alert {
	background-color: rgba($light, .92);
	padding: 12px;
	overflow: hidden;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	border: 2px solid $border;
	.close {
		border: 1px solid $border;
		border-radius: 100%;
		width: 19px;
		line-height: 18px;
		height: 19px;
		display: block;
		text-align: center;
		color: $border;
		float: right;
		cursor: pointer;
	}
	p {
		margin-bottom: 6px;
	}
	&.alert-message, &.alert-info {
		border: 2px solid $main;
		.close {
			border: 1px solid $main;
			color: $main;
		}
	}
	&.alert-warning, &.alert-error {
		border: 2px solid $red;
		.close {
			border: 1px solid $red;
			color: $red;
		}
	}
	&.alert-success, &.alert-notice {
		border: 2px solid $green;
		.close {
			border: 1px solid $green;
			color: $green;
		}
	}
}

// Base Styling Inputs
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea {
	border: 1px solid $border;
}

input[type=radio], input[type=checkbox] {
	filter: hue-rotate(140deg);
}

// Icons for Base Elements
a[href^="mailto:"]:not(.ampz_email) {
	@include icon($icon-envelop);
}

a[href^="tel:"] {
	@include icon($icon-phone);
}

span.fax {
	@include icon($icon-printer);
}

.btn-edit {
	@include icon($icon-pencil);
}

.btn-delete {
	@include icon($icon-cancel-circle);
}

.btn-save {
	@include icon($icon-checkmark);
}

.btn-search {
	@include icon($icon-search);
}

.btn-more {
	@include icon($icon-arrow-right2);
}

*[data-title] {
	cursor: pointer;
	position: relative;
	&:hover {
		&:after {
			content: attr(data-title);
			padding: 4px 8px;
			color: #333;
			position: absolute;
			left: 12px;
			top: 82%;
			@include font-size(14);
			max-width: 500px;
			text-transform: initial;
			z-index: 20;
			box-shadow: 0 0 4px #222;
			background: $bg;
		}
	}
}

// Frontend Joomla-native Popups
.popover {
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	background-color: $light;
	border: 1px solid $border;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	white-space: normal;
	position: absolute;
	&.fade {
		opacity: 0;
		-webkit-transition: opacity .15s linear;
		transition: opacity .15s linear;
		&.in {
			opacity: 1;
		}
	}
	&.top {
		margin-top: -10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-bottom-width: 0;
			border-top-color: $border;
			bottom: -11px;
			&:after {
				bottom: 1px;
				margin-left: -10px;
				border-bottom-width: 0;
				border-top-color: $light;
			}
		}
	}
	&.right {
		margin-left: 10px;
		> .arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-left-width: 0;
			border-right-color: $border;
			&:after {
				left: 1px;
				bottom: -10px;
				border-left-width: 0;
				border-right-color: $light;
			}
		}
	}
	&.bottom {
		margin-top: 10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-top-width: 0;
			border-bottom-color: $border;
			top: -11px;
			&:after {
				top: 1px;
				margin-left: -10px;
				border-top-width: 0;
				border-bottom-color: $light;
			}
		}
	}
	&.left {
		margin-left: -10px;
		> .arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: $border;
			&:after {
				right: 1px;
				border-right-width: 0;
				border-left-color: $light;
				bottom: -10px;
			}
		}
	}
	> .arrow {
		border-width: 11px;
		&:after {
			border-width: 10px;
			content: "";
		}
	}
	> .arrow, > .arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.popover-title {
		margin: 0;
		padding: 8px 14px;
		@include font-size(14);
		font-weight: 400;
		line-height: 18px;
		background-color: $main;
		color: $light;
		border-bottom: 1px solid $border;
		border-radius: 5px 5px 0 0;
	}
	.popover-content {
		padding: 9px 14px;
	}
}

.popover.bottom > .arrow:after, .popover.left > .arrow:after, .popover.right > .arrow:after, .popover.top > .arrow:after {
	content: " ";
}

span.copyright {
	display: block;
	text-align: right;
	color: $light;
	@include font-size(14);
}

.content_rating, form.rate_content {
	p.unseen.element-invisible {
		display: none;
	}
}

.content_rating {
	float: right;
	span.stars {
		color: gold;
		font-size: 27px;
		line-height: 24px;
		cursor: pointer;
	}
	span.rating_count {
		position: relative;
		top: -3px;
	}
}

// Material Radios/Checkbox
//.radio {
//	margin: 16px 0;
//	input[type="radio"] {
//		opacity: 0;
//		position: absolute;
//		&:checked + label:before {
//			border-color: $md-radio-checked-color;
//			animation: ripple 0.2s linear forwards;
//		}
//		&:checked + label:after {
//			transform: scale(1);
//		}
//	}
//	label {
//		display: inline-block;
//		position: relative;
//		padding: 0 ($md-radio-size + 10px);
//		margin-bottom: 0;
//		cursor: pointer;
//		vertical-align: bottom;
//		&:before, &:after {
//			position: absolute;
//			content: '';
//			border-radius: 50%;
//			transition: all .3s ease;
//			transition-property: transform, border-color;
//		}
//		&:before {
//			left: 0;
//			top: 0;
//			width: $md-radio-size;
//			height: $md-radio-size;
//			border: 2px solid $md-radio-border-color;
//		}
//		&:after {
//			top: 5px;
//			left: 5px;
//			width: $md-radio-checked-size;
//			height: $md-radio-checked-size;
//			transform: scale(0);
//			background: $md-radio-checked-color;
//		}
//	}
//}
//
//.checkbox {
//	margin: 16px 0;
//	input[type="checkbox"] {
//		opacity: 0;
//		position: absolute;
//		&:checked + label:before {
//			border-color: $md-radio-checked-color;
//			animation: ripple 0.2s linear forwards;
//		}
//		&:checked + label:after {
//			transform: scale(1);
//		}
//	}
//	label {
//		display: inline-block;
//		position: relative;
//		padding: 0 ($md-radio-size + 10px);
//		margin-bottom: 0;
//		cursor: pointer;
//		vertical-align: bottom;
//		&:before, &:after {
//			position: absolute;
//			content: '';
//			transition: all .3s ease;
//			transition-property: transform, border-color;
//		}
//		&:before {
//			left: 0;
//			top: 0;
//			width: $md-radio-size;
//			height: $md-radio-size;
//			border: 2px solid $md-radio-border-color;
//		}
//		&:after {
//			top: 5px;
//			left: 5px;
//			width: $md-radio-checked-size;
//			height: $md-radio-checked-size;
//			transform: scale(0);
//			background: $md-radio-checked-color;
//		}
//	}
//}

/* Tooltips */
.tip-wrap {
	z-index: 10000;
	.tip {
		float: left;
		background: $bg;
		border: 1px solid $border;
		padding: 5px;
		max-width: 200px;
	}
	.tip-title {
		padding: 0;
		margin: 0;
		color: $main;
		font-size: 100%;
		font-weight: bold;
		margin-top: -15px;
		padding-top: 15px;
		padding-bottom: 5px;
	}
	.tip-text {
		font-size: 100%;
		margin: 0;
	}
}
.search {
	span.icon-search {
		display: none;
	}
}
select#ordering {
	min-width: 149px;
}
