* {
	font-size: $base-font-size;
	font-family: $base-font-family;
	line-height: 1.4;
}

body {
	background-color: $light;
	position: relative;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	background: rgba($light, .94);
	position: fixed;
	z-index: 4;
	.logo {
		img {
			width: 250px;
			margin-top: 12px;
		}
		float: left;
	}
}

main {
	padding-top: 30px;
}

footer {
	width: 100%;
	float: left;
	clear: both;
	background: $light;
	padding-top: 24px;
	border-top: 1px solid #eee;
	padding-bottom: 24px;
	h3 {
		margin-bottom: 24px;
	}
	p {
		font-weight: 300;
	}
	a {
		font-weight: 300;
		text-decoration: none;
		color: $base;
	}
	.moduletable h3.page-header {
		border: 0;
		margin-bottom: 24px;
		padding: 0;
	}
}
@media #{$large}{
	.com-users-login__submit.control-group {
		width: 50%;
		float: left;
		clear: none !important;
		max-width: 50%;
	}
}
.match {
	transition: none !important;
}

body .cc_banner-wrapper:hover {
	a.cc_more_info {
		color: $main !important;
	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}
div#eb-category-page-timeline {
	margin-bottom: 24px;
}
div#eb-upcoming-events-page-timeline {
	margin-bottom: 24px;
}
.top_top {
	position: fixed;
	bottom: 64px;
	@media #{$large}{
		bottom: 0;
	}
	width: 100%;
	box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
	height: 5px;
	background: $main;
	text-align: center;
	z-index: 2;
	&.stick {
		position: relative;
		bottom: 0 !important;
	}
	button.scroll_to_top {
		background: $main;
		display: inline-block;
		height: 45px;
		position: relative;
		width: 45px;
		top: -44px;
		box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
		z-index: 2;
		border-radius: 100% 100% 0 0;
		span.red_arrow {
			background: $light;
			color: $link;
			display: block;
			width: 29px;
			height: 29px;
			border-radius: 100%;
			font-weight: lighter;
			line-height: 35px;
			font-size: 28px;
			margin: 0 auto;
		}
	}
}

a.cc_more_info {
	font-size: inherit;
}

@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: light !important;
	}
}

.visually-hidden {
	display: none;
}

body {
	.wf-columns {
		@media #{$xlarge}{
			gap: 48px;
		}
	}
}

// compat fixes
.demo-typoDiv {
	margin-bottom: 24px;
}

#jvhelpdesk-dir #mainbody div.home-blocks div.home-block {
	height: 100%;
}

#jvhelpdesk-dir {
	.btn {
		padding: 4px 12px;
		width: auto;
	}
}

.row-fluid.home-blocks {
	display: flex;
}

// Akeeba Engage Styling
section#akengage-comments-section {
	div#akengage-comment-form {
		background: #efefef;
		h4 {
			color: $link;
		}
		label {
			@include font-size(14);
		}
		label.checkbox {
			margin-bottom: 12px;
			display: block;
			input {
				display: block;
				opacity: 1;
				position: relative;
				float: left;
				top: 6px;
				margin-right: 12px;
				margin-bottom: 24px;
				clear: left;
			}
		}
		.akengage-comment-captcha-wrapper {
			float: left;
			clear: both;
			margin-top: 24px;
			margin-bottom: 24px;
		}
		.btn-group {
			.btn {
				padding: 11px;
			}
		}
	}
}

// EB Events
.eb-container {
	span.eb-individual-price {
		background: $link;
	}
	.eb-taskbar a.btn {
		padding: 12px;
	}
	.eb-taskbar ul li {
		float: right;
	}
	h2.eb-even-title-container {
		font-size: 28px;
		font-weight: 500;
		margin-top: 6px;
		margin-bottom: 6px;
	}
	.eb-event-date-day {
		padding-top: 1px;
	}
	.eb-event-date {
		.eb-event-date-month, .eb-event-date-year {
			font-size: 11px;
		}
	}
}

div#ampz_inline_top {
	margin-bottom: 24px;
}

.ampz_container {
	ul {
		li {
			a {
				i, span {
					transition: .3s all ease 0s;
				}
				&:hover {
					color: $link;
					border-color: $link;
					i, span {
						color: $link;
					}
				}
			}
		}
	}
}

.nav-pills > .active > a {
	background-color: $link;
}

div.cc-window {
	.cc-message {
		@include font-size(14);
		color: $light;
	}
}

#eb-events ~ .pagination ul {
	box-shadow: none;
	li {
		display: inline-block;
	}
}

.ticket-header div {
	color: #efefef;
}

.eb-taskbar ul li:before {
	display: none !important;
}

.eb-container .eb-taskbar a.btn {
	text-transform: none;
	padding-top: 3px;
	padding-bottom: 3px;
}

.eb-container {
	.control-group {
		@media #{$xlarge}{
			.control-label {
				width: 300px;
			}
			.controls {
				margin-left: 300px;
			}
		}
		@media #{$small-max}{
			.control-label {
				width: 100%;
				clear: both;
			}
			.controls {
				margin-left: 0;
				width: 100%;
				clear: both;
			}
		}
	}
	input[type=checkbox], input[type=radio] {
		margin-top: 0;
	}
	input#btn-submit {
		margin-top: 12px;
	}
}

body {
	.checkbox input[type=checkbox], .radio input[type=radio] {
		float: none;
	}
}
