.sketchwrap_slider {
	overflow: hidden;
	@include clear();
	&.noslider{
		min-height: 124px;
	}
	.sketchslider_pro-wrap {
		.sketchslider_pro {
			margin-bottom: 60px;
			position: relative;
			min-height: 61vh;
			@media #{$landscape}{
				@media #{$medium-max}{

					min-height: 100vh;
				}
			}
			ul.slides li{
				min-height: 61vh;
				@media #{$landscape}{
					@media #{$medium-max}{

						min-height: 100vh;
					}
				}
			}
			.imageHolder{
				background-size: cover;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 100%;
				bottom: 0;
				width: 100%;
				display: flex;
				align-items: flex-end;
				background-position: center center;
				.row{
					width: 100%;
				}
			}
			.box {
				background: transparent;
				color: $base;
				bottom: 50px;

				span.slider_headline, h1 {
					@include font-size(45);
					@media #{$hd}{
						@include font-size(72);
					}
					font-weight: 900;
					color: $main;
					text-transform: uppercase;
					margin-bottom: 0;
				}
				* {
					color: $base;
					@include font-size(16);
					@media #{$hd}{
						@include font-size(22);
					}
				}
				h1 {
					color: $main;
					@include font-size(30);
					@media #{$large}{
						@include font-size(45);
					}
					@media #{$hd}{
						@include font-size(48);
					}
				}
				h5 {
					color: $link;
					@include font-size(14);
				}
				.btn {
					@include button();
					&:hover{
						background: $main !important;
						border: 1px solid $main !important;
					}
				}
			}
			ul.slides {
				> li {
					.columns {
						min-height: 0;
					}
					.box {
						z-index: 3;
					}
					img {
						z-index: 1;
					}
				}
			}
		}
	}
}