.blog, .blog-featured {
	@include clear();
	margin-bottom: 30px;
	.blog-items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		margin-right: -12px;
		@media #{$large}{
			margin-left: -24px;
			margin-right: -24px;
		}
		&.masonry-2, &.columns-2 {
			.blog-item {
				width: 100%;
				@media #{$large}{
					width: 50%;
				}
			}
		}
		&.masonry-3, &.columns-3 {
			.blog-item {
				width: 100%;
				@media #{$large}{
					width: 33.333333%;
				}
				.itemWrap .item-image{
					width: 100%;
					max-width: 100%;
				}
			}
		}
		&.masonry-4, &.columns-4 {
			.blog-item {
				width: 100%;
				@media #{$large}{
					width: 25%;
				}
			}
		}

	}
	.blog-item {
		padding: 12px;
		@media #{$large}{
			padding: 24px;
		}
		.page-header{
			@include clear();
			h2{
				margin-bottom: 0;
			}
		}
		.item-title a{
			color: $main;
		}
		dd.category-name{
			@include font-size(12);
		}
		dd.published span.icon-calendar{
			display: none;
		}
	}
	.items-row {
		margin-bottom: 48px;
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		margin-right: -12px;
		@media #{$large}{
			margin-left: -24px;
			margin-right: -24px;
		}
		.span6 {
			width: 100%;
			@media #{$large}{
				width: 50%;
			}
			float: left;
			.item {
				h2 {
					@include font-size(26);
				}
				.pull-left.item-image {
					@include clear();
					max-width: 100%;
					+ p {
						clear: both;
					}
				}
			}
		}
		.span4{
			width: 100%;
			@media #{$large}{
				width: 33.3333%;
			}
			float: left;
			.item {
				h2 {
					@include font-size(26);
				}

				.pull-left.item-image {
					@include clear();
					max-width: 100%;
					+ p {
						clear: both;
					}
				}
			}
		}
		.span12, .items-leading{
			@include clear();
			.item-image+.item-content{
				@media #{$medium}{
					width: 50%;
					float: left;
					clear: none;
				}
			}
			.item-image{
				@media #{$medium}{
					margin-right: 0;
					padding-right: 36px;
				}
			}
		}
	}
	.items-more {
		@include clear();
		margin-bottom: 48px;
	}
	a.btn.btn-mini.pull-right {
		display: none;
	}
	aside.akenage-comments-counter--blog {
		display: none;
		data {
			@include font-size(14);
		}
	}
	.cat-children {
		@include clear();
		margin-bottom: 24px;
	}
	.pagination {
		margin-bottom: 48px;
	}
	aside.akenage-comments-counter--featured{
		display: none;
	}
	.items-leading{
		@include clear();
		margin-bottom: 24px;
		.item-content{
			@media #{$medium}{
				width: 50%;
				float: left;
				clear: none;
			}
		}
		.item-image{
			@media #{$medium}{
				margin-right: 0;
				max-width: 50%;
				padding-right: 36px;
			}
		}
	}
}
.blog-featured p.readmore {

}
.category-list {
	@include clear();
}

.chzn-container {
	a.chzn-single {
		background: $light;
		padding-top: 3px;
		padding-bottom: 3px;
		height: 32px;
		> div {
			top: 4px;
		}
	}
	.chzn-drop {
		ul.chzn-results {
			li {
				@include font-size(14);
			}
		}
	}
}

