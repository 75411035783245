// Zeug fürs Grid
@use "sass:math";

@mixin clearfix() {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin ghostVerticalAlign() {
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		width: .1px;
	}
}

// Button
@mixin button($bgcolor: $link) {
	background: transparent;
	color: $base;
	border: 1px solid $base;
	padding: 10px 16px;
	text-transform: uppercase;
	font-weight: 500;
	display: inline-block;
	transition: .3s all ease 0s;
	border-radius: 0;
	@include font-size(15);
	&:hover {
		background: $main;
		color: $light;
		border: 1px solid $main;
	}
	&.btn-small {
		padding: 6px 12px;
		@include font-size(14);
	}
}

@mixin inheriter() {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
}

// Hintergrundblock
@mixin bgblock() {
	margin-bottom: 24px;
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
	background: $light;
	display: block;
	float: left;
	width: 100%;
}

// Clear all
@mixin clear() {
	float: left;
	width: 100%;
	clear: both;
	display: block;
}

// Full width Hintergrundfarbe
@mixin bgfull($bgcolor: $main, $spacing: 48px) {
	background-color: $bgcolor;
	position: relative;
	padding-top: $spacing;
	padding-bottom: $spacing;
	float: left;
	display: block;
	width: 100%;
	&:before, &:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 500%;
		background-color: $bgcolor;
		top: 0;
	}
	&:before {
		right: 100%;
	}
	&:after {
		left: 100%;
	}
}

// Use Rem and Pixel Fallback
@mixin font-size($sizeValue: $base_font_math, $lineHeight: 1.6) {
	font-size: ($sizeValue) + px;
	font-size: math.div($sizeValue, $base_font_math ) + rem;
	line-height: $lineHeight;
}

// Icon to before
@mixin icon($inputicon, $dir: left) {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@if $dir == "left" {
		&:before {
			content: $inputicon;
			font-family: $icon;
			font-size: $base-font-size - 2;
			padding-right: 6px;
		}
	}
	@if $dir == "none" {
		&:before {
			content: $inputicon;
			font-size: $base-font-size - 2;
			font-family: $icon;
		}
	}
	@if $dir == "right" {
		&:after {
			content: $inputicon;
			font-size: $base-font-size - 2;
			font-family: $icon;
			padding-left: 6px;
		}
	}
}

// Keyframes
@keyframes ripple {
	0% {
		box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
	}
	50% {
		box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
	}
	100% {
		box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
	}
}