@font-face {
	font-family: 'bare';
	src: url('fonts/bare.eot?58a3c0');
	src: url('fonts/bare.eot?58a3c0#iefix') format('embedded-opentype'),
	url('fonts/bare.ttf?58a3c0') format('truetype'),
	url('fonts/bare.woff?58a3c0') format('woff'),
	url('fonts/bare.svg?58a3c0#bare') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextRegular/font.woff2') format('woff2'), url('webFonts/OkomitoNextRegular/font.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextLightItalic/font.woff2') format('woff2'), url('webFonts/OkomitoNextLightItalic/font.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextLight/font.woff2') format('woff2'), url('webFonts/OkomitoNextLight/font.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextExtraBoldItalic/font.woff2') format('woff2'), url('webFonts/OkomitoNextExtraBoldItalic/font.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextExtraBold/font.woff2') format('woff2'), url('webFonts/OkomitoNextExtraBold/font.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextMedium/font.woff2') format('woff2'), url('webFonts/OkomitoNextMedium/font.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "OkomitoNext";
	src: url('webFonts/OkomitoNextMediumItalic/font.woff2') format('woff2'), url('webFonts/OkomitoNextMediumItalic/font.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Font Awesome 5 Brands";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url('webFonts/fa-brands-400.eot');
	src: url(webFonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(webFonts/fa-brands-400.woff2) format("woff2"), url(webFonts/fa-brands-400.woff) format("woff"), url(webFonts/fa-brands-400.ttf) format("truetype"), url(webFonts/fa-brands-400.svg#fontawesome) format("svg")
}

.fab {
	font-family: "Font Awesome 5 Brands"
}

@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(webFonts/fa-regular-400.eot);
	src: url(webFonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(webFonts/fa-regular-400.woff2) format("woff2"), url(webFonts/fa-regular-400.woff) format("woff"), url(webFonts/fa-regular-400.ttf) format("truetype"), url(webFonts/fa-regular-400.svg#fontawesome) format("svg")
}

.fab, .far {
	font-weight: 400
}

@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(webFonts/fa-solid-900.eot);
	src: url(webFonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(webFonts/fa-solid-900.woff2) format("woff2"), url(webFonts/fa-solid-900.woff) format("woff"), url(webFonts/fa-solid-900.ttf) format("truetype"), url(webFonts/fa-solid-900.svg#fontawesome) format("svg")
}

.fa, .far, .fas {
	font-family: "Font Awesome 5 Free"
}

.fa, .fas {
	font-weight: 900
}

// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-math: 16;
$base-font-family: 'OkomitoNext', sans-serif;
$icon: bare;
$fontawesome: Font Awesome\ 5 Free;
/* Colors */
$base: rgb(102, 102, 102);
$link: rgb(235, 87, 13);
$link_hover: darken(rgb(235, 87, 13), 10%);
$bgbox: #FCFCFC;
$main: rgb(37, 37, 37);
$dark: #333;
$light: #fff;
$border: #adb7bf;
$bg: #cbcbcb;
$green: #3B8130;
$red: #b21f28;
// Radio/Chheckbox Styles
$md-radio-checked-color: $main;
$md-radio-border-color: $border;
$md-radio-size: 20px;
$md-radio-checked-size: 14px;
$md-radio-ripple-size: 15px;
// Styles
$br: 12px;
$box-shadow: 0 0 10px -1px #333;
// Icons
$icon-home: "\e900";
$icon-pencil: "\e905";
$icon-image: "\e90d";
$icon-music: "\e911";
$icon-book: "\e91f";
$icon-books: "\e920";
$icon-profile: "\e923";
$icon-file-text2: "\e926";
$icon-price-tag: "\e935";
$icon-phone: "\e942";
$icon-envelop: "\e945";
$icon-location: "\e947";
$icon-alarm: "\e950";
$icon-calendar: "\e953";
$icon-printer: "\e954";
$icon-mobile: "\e958";
$icon-floppy-disk: "\e962";
$icon-bubble2: "\e96e";
$icon-user: "\e971";
$icon-search: "\e986";
$icon-cogs: "\e995";
$icon-gift: "\e99f";
$icon-briefcase: "\e9ae";
$icon-truck: "\e9b0";
$icon-clipboard: "\e9b8";
$icon-menu: "\e9bd";
$icon-earth: "\e9ca";
$icon-warning: "\ea07";
$icon-cancel-circle: "\ea0d";
$icon-blocked: "\ea0e";
$icon-cross: "\ea0f";
$icon-checkmark: "\ea10";
$icon-arrow-right: "\ea34";
$icon-arrow-right2: "\ea3c";
$icon-circle-right: "\ea42";
$icon-amazon: "\ea87";
$icon-google-plus2: "\ea8c";
$icon-facebook2: "\ea90";
$icon-instagram: "\ea92";
$icon-whatsapp: "\ea93";
$icon-youtube: "\ea9d";
$icon-linkedin: "\eac9";
$icon-xing: "\ead3";
// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;
$hd-screen: 1650px !default;
$screen: "only screen" !default;
$small: "only screen and (min-width: #{$small-screen})" !default;
$small-max: "only screen and (max-width: #{$small-screen - 1})";
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-max: "only screen and (max-width: #{$medium-screen - 1})";
$large: "only screen and (min-width:#{$large-screen})" !default;
$large-max: "only screen and (max-width: #{$large-screen - 1})";
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;
$xlarge-max: "only screen and (max-width: #{$xlarge-screen - 1})";
$hd: "only screen and (min-width:#{$hd-screen})" !default;
$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;


