#login-form {
	.userdata {
		label {
			@include font-size(14, 1.5);
			min-width: 148px;
			width: 100%;
			display: inline-block;
		}
		input {
			border: 1px solid grey;
			@include font-size(14, 1.5);
			width: 100%;
		}
		ul.unstyled {
			margin-bottom: 0;
			li {
				margin-bottom: 0;
				@include font-size(14, 1.5);
				a {
					@include font-size(14, 1.5);
				}
			}
		}
	}
	button[type="submit"] {
		margin-bottom: 0;
		&:before {
			content: $icon-user;
			font-family: $icon;
			padding-right: 6px;
		}
	}
	.login-greeting {
		margin-bottom: 12px;
	}
}

body#com_users {
	.login, .profile, .profile-edit, .logout, .reset, .remind, .reset-confirm, .reset-complete {
		background: $light;
		padding: 12px;
		width: 100%;
		float: left;
		margin-bottom: 6px;
		display: block;
		ul.btn-toolbar {
			list-style: none;
			margin-left: 0;
			li {
				margin-right: 12px;
				margin-bottom: 12px;
				display: inline-block;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		@media #{$large}{
			padding: 24px;
		}
		.control-group, dl {
			width: 100%;
			float: left;
			clear: both;
			margin-bottom: 6px;
			.control-label, .controls, dd, dt {
				width: 100%;
				float: left;
				display: block;
				margin-bottom: 6px;
				@media #{$medium}{
					width: 50%;
				}
			}
			select {
				width: 310px;
				max-width: 100%;
			}
		}
		fieldset {
			border: 1px solid #eee;
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			legend {
				padding: 0 6px;
				font-size: 14px;
				font-weight: bold;
				font-family: $base-font-family;
			}
		}
		.chzn-container * {
			font-family: $base-font-family;
		}
	}
	ul.nav.nav-tabs.nav-stacked {
		float: left;
		margin-bottom: 24px;
	}
}

.moduletable.login {
	ul {
		float: left;
		width: 100%;
	}
	.login .control-group .controls {
		width: 100% !important;
	}
}

.com-users-login__options.list-group a {
	display: block;
}

.com-users-login__options.list-group a:before {
	content: '• ';
}

button.wf-editor-toggle.btn.btn-link {
	right: 0;
	left: auto;
}

span.fa.fa-remove {
	@include icon($icon-cross, 'none');
}

table.table.table-striped.table-bordered.table-hover th, table.table.table-striped.table-bordered.table-hover th * {
	color: #333 !important;
}

#hdp-container input#filter_search {
	margin-right: 12px;
}

a.hpd-add-new-ticket-link {
	position: relative;
	top: 42px;
}

a.hpd-add-new-ticket-link i.icon-new {
	padding-bottom: 0;
	top: -1px;
	padding-top: 8px;
}
a.hpd-add-new-ticket-link:hover i.icon-new:before {
	filter: invert(1);
}
.page-item.active .page-link{
	background-color: $link;
	border-color: $link;
	color: #fff;
}
.pagination li.disabled.page-item {
	position: relative;
	//top: 5px;
}
table.table.table-striped.table-bordered tr th {
	border-color: transparent;
}
.btn-check:focus+.btn-primary, .btn-primary:focus{
	background-color: $link;
	border-color: $link;
	box-shadow: none;
}
body#com_helpdeskpro form#adminForm table th {
	white-space: nowrap;
}
