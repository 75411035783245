.breadcrumb_wrap {
	width: 100%;
	float: left;
	.moduletable {
		ol.breadcrumb {
			list-style: none;
			margin-left: 0;
			display: block;
			float: left;
			background: $light;
			padding-left: 12px;
			border-radius: $br;
			padding-right: 12px;
			padding-top: 6px;
			padding-bottom: 6px;
			margin-bottom: 24px;
			li {
				display: inline-block;
				span, a {
					padding: 0 5px;
					@media #{$large}{
						font-size: 14px;
					}
				}
				span{
					color: $base;
				}
				a{
					color: $link;
					&.pathway{
						padding: 0;
					}
				}
				.divider {
					padding: 0;
					&:before {
						content: $icon-circle-right;
						font-family: $icon;
						font-size: 12px;
					}
				}
				&:first-child {
					a, span{
						padding: 0;
					}
					.icon-location {
						&:before{
							content: $icon-home;
							padding-right: 6px;
							font-size: 12px;
							@media #{$large}{
								font-size: 14px;
							}
						}
					}
				}
				&:nth-child(2){
					span.divider{
					}
				}
			}
		}
	}
}