.sidr {
	position: absolute;
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 999999;
	width: 260px;
	overflow-x: none;
	overflow-y: auto;
	font-family: $base-font-family;
	font-size: 15px;
	background: $base;
	color: $light;
	.sidr-inner {
		padding: 0 0 15px;
		> p {
			margin-left: 15px;
			margin-right: 15px
		}
	}
	&.right {
		left: auto;
		right: -260px
	}
	&.left {
		left: -260px;
		right: auto
	}
	h1, h2, h3, h4, h5, h6{
		font-size: 11px;
		font-weight: normal;
		padding: 0 15px;
		margin: 0 0 5px;
		color: $light;
		line-height: 24px;
	}
	p {
		font-size: 13px;
		margin: 0 0 12px
	}
	p a {
		color: $light;
	}
	> p {
		margin-left: 15px;
		margin-right: 15px;
	}
	ul {
		display: block;
		margin: 0 0 15px;
		padding: 0;
		li {
			display: block;
			margin: 0;
			position: relative;
			a, span{
				padding: 7px 15px;
				display: block;
				text-decoration: none;
				color: #fff;
				font-weight: 500;
				text-transform: uppercase;
			}
			&:first-child{
				a, span{
				}
			}
			&:hover, &.active, &.sidr-class-active{
				>a, >span{
					box-shadow: inset 0 0 12px -3px $base;
				}
			}
			ul{
				border-bottom: none;
				margin: 0;
				li {
					font-size: 13px;
					&:last-child {

					}
					span, a{
						color: $light;
						text-transform: none;
						padding-left: 30px
					}
					&:hover, &.active, &.sidr-class-active{
						>a, >span{
							box-shadow: inset 0 0 12px -3px $base;
						}
					}
					ul li a{
						padding-left: 44px;
					}
				}
			}
		}
	}
	form {
		margin: 0 15px
	}
	label {
		font-size: 13px
	}
	input[type="text"], input[type="password"], input[type="date"],input[type="datetime"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"],  input[type="url"], textarea, select{
		width: 100%;
		font-size: 13px;
		padding: 5px;
		margin: 0 0 10px;
		border-radius: 2px;
		border: none;
		background: rgba($base, 0.1);
		color: rgba($light, 0.6);
		display: block;
		clear: both;
	}
	input[type=checkbox] {
		width: auto;
		display: inline;
		clear: none;
	}
	input[type=button], input[type=submit] {
		color: $base;
		background: $light;
	}
	input[type=button]:hover, input[type=submit]:hover {
		background: rgba($light, 0.9);
	}
	span.toggl0r{
		position: absolute;
		transform: rotate(90deg);
		right: 12px;
		top: 12px;
		box-shadow: none !important;
		cursor: pointer;
		transition: .3s transform ease 0s;
		width: 0;
		height: 0;
		border-top: 8px solid transparent!important;
		border-bottom: 8px solid transparent;
		border-left:12px solid $light;
		display: block;
		padding: 0;
		&.open {
			transform: rotate(-90deg);
			border-left-color: $link;
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 40px;
			height: 70px;
			transform: translate(-50%, -50%);
		}
	}
}

span.fab.fa-twitter {
	font-family: 'FONTAWESOME';
}


